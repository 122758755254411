import { template as template_426c0bdfad8c4e1db0895686f14fc7df } from "@ember/template-compiler";
const SidebarSectionMessage = template_426c0bdfad8c4e1db0895686f14fc7df(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
