import { template as template_aef4ba62f22f4a3eb3c8025703c277c8 } from "@ember/template-compiler";
const FKControlMenuContainer = template_aef4ba62f22f4a3eb3c8025703c277c8(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
